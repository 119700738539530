<template>
<div class="container border-container">
    <div class="search-container">
        <span class="list-title">学校列表</span>
        <el-button type="primary" icon="el-icon-plus" @click="handleDialogShow('')">分配学校</el-button>
    </div>
    <div class="table-container">
        <el-table :data="orgSchoolList" tooltip-effect="dark" style="width: 100%" size="medium"
            :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
            @selection-change="handleSelectionChange"  :row-key="getRowKey"
            :cell-style="{height: '50px',padding: '5px 0'}" header-align="center">
            <el-table-column type="selection" width="42" :reserve-selection="true" align="center"></el-table-column>
            <el-table-column prop="name" label="学校名称" min-width="150" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <div class="hover-text-colourful fz-bold" @click="routerChange(1, scope.row)">
                        {{scope.row.name}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="contact_name" label="联系人姓名" min-width="100" align="center">
                <template slot-scope="scope">
                    {{scope.row.contact_name}}
                </template>
            </el-table-column>
            <el-table-column prop="contact_phone" label="联系人手机" min-width="120" align="center">
                <template slot-scope="scope">
                    {{scope.row.contact_phone}}
                </template>
            </el-table-column>
            <el-table-column prop="create_time" label="创建时间" min-width="155" align="center">
                <template slot-scope="scope">
                    {{scope.row.create_time | timeFilter}}
                </template>
            </el-table-column>
            <el-table-column label="操作" min-width="100" align="center">
                <template slot-scope="scope">
                    <el-button type="text" class="icon-btn" @click="routerChange(1,scope.row)">
                        <el-tooltip placement="top" content="学校详情">
                            <i class="iconfont icon-check" />
                        </el-tooltip>
                    </el-button>
                    <!-- <el-button type="text" class="icon-btn" @click="handleRemove(scope.row.id)">
                        <el-tooltip placement="top" content="移出机构">
                            <i class="iconfont icon-delete" />
                        </el-tooltip>
                    </el-button> -->
                </template>
            </el-table-column>
            <div class="" slot="empty">
                <no-data></no-data>
            </div>
        </el-table>
    </div>
    <div class="page-container">
        <el-pagination layout="total, sizes, prev, pager, next" background
            @size-change="pageSizeChange"
            @current-change="pageChange"
            :current-page="currentPage"
            :page-sizes="[total, 10, 15, 20, 25]"
            :total="total" :page-size="pageSize">
        </el-pagination>
    </div>
    <div class="dialog-container">
        <el-dialog class="org-dialog" :title="'分配学校'"
            :visible.sync="dialogVisible" width="500px"
            :before-close="handleDialogClose">
            <div class="dialog-content">
                <div class="search-area">
                    <el-input placeholder="请输入内容" class="input-with-select"
                        v-model="searchForm.name" @keyup.enter.native="handleSearchPageChange(1)"
                        clearable @clear="handleSearchPageChange(1)">
                        <el-button slot="append" icon="el-icon-search" size="mini" @click="handleSearchPageChange(1)"></el-button>
                    </el-input>
                </div>
                <div class="check-area">
                    <div class="check-item" v-for="(item, index) in schoolList" :key="index"
                        @click="handleSelectChange(item)" :class="{disabled: item.disabled,checked: item.checked}">
                        <div class="check-box">
                            <i class="icon el-icon-success"/>
                        </div>
                        <div class="check-name">{{item.name}}</div>
                    </div>
                </div>
                <div class="page-area">
                    <el-pagination
                        layout="prev, pager, next"
                        background
                        :page-size="8"
                        :current-page="searchPage"
                        :pager-count="5"
                        @current-change="handleSearchPageChange"
                        :total="searchTotal">
                    </el-pagination>
                </div>
            </div>
            <span slot="footer" class="dialog-footer" style="display:flex;justify-content: center;">
                <el-button @click="handleDialogClose">取 消</el-button>
                <el-button type="primary" @click="handleEditConfirm">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</div>
</template>

<script>
import Core from 'core';

export default {
    components: {
        NoData: () => import('@/components/Empty.vue')
    },
    props: {
        orgId: {
            type: [Number,String]
        },
    },
    data() {
        return {
            userType: Core.Data.getUserType(),
            USER_TYPE: Core.Const.USER_TYPE,
            total: 0,
            currentPage: 1,
            pageSize: 10,
            orgSchoolList: [],

            dialogVisible: false,

            schoolList: [],     // 被选择的学校列表
            schoolSelectIds: [],// 当下选择的学校 ids

            searchForm: {
                name: '',
            },
            searchTotal: 0,
            searchPage: 1,
        };
    },
    created() {
        this.getOrgSchoolList();

        this.getSchoolList();
        this.getAllOrgSchoolList();
    },
    filters: {
    },
    methods: {
        getRowKey(row) {
            return row.id;
        },
        handleSelectionChange(val) { // 表格选择
            console.log("handleSelectionChange -> val", val)
        },
        pageChange(page) { // 页面改变
            this.currentPage = page;
            this.getOrgSchoolList();
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.getOrgSchoolList();
        },
        routerChange(key, q = '') { // 路由改变
            console.log("routerChange -> q", q)
            let routeUrl;
            switch (key) {
                case 1:
                    routeUrl = this.$router.resolve({
                        path: '/school/school-detail',
                        query: {
                            id: q.id
                        }
                    })
                break;
            }
            window.open(routeUrl.href, '_blank')
        },
        getOrgSchoolList() { // 获取 机构绑定学校 列表
            Core.Api.Org.listSchool(
                this.orgId,
                this.currentPage,
                this.pageSize,
            ).then((res) => {
                console.log("getOrgSchoolList -> res", res)
                this.total = res.count
                this.orgSchoolList = res.list
            });
        },

        handleRemove(id) { // 删除 机构绑定学校
            this.$confirm('确定要将学校移出机构吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$message.success('暂无移出功能');
                /* Core.Api.Org.removeSchool(id).then((res) => {
                    this.$message.success('移出成功!');
                    this.getOrgSchoolList();
                }).catch(err => {
                    console.log("handleRemove -> err", err)
                }) */
            })
        },

        getSchoolList() { // 获取被选择学校
            Core.Api.School.listSearchByName(
                this.searchPage,
                8,
                this.searchForm.name
            ).then(res => {
                this.searchTotal = res.count
                let list = res.list.map(item => ({
                    id: item.id,
                    name: item.name,
                    disabled: item.org_id ? item.org_id != this.orgId : false,
                    checked: false
                }))
                this.schoolList = list
                this.changeCheckStatus();
                console.log("getSchoolList -> this.schoolList", this.schoolList)
            })
        },
        async getAllOrgSchoolList() {  // 获取所有已绑定的学校
            let page = 1
            let arr = []
            while (true) {
                let res = await Core.Api.Org.listSchool(this.orgId, page++)
                let list = res.list
                if (list.length === 0) {
                    break;
                }
                arr = [...arr, ...list]
            }
            this.schoolSelectIds = arr.map(item => item.id)
        },
        changeCheckStatus() {
            console.log("changeCheckStatus -> this.schoolSelectIds", this.schoolSelectIds)
            console.log("changeCheckStatus -> this.schoolList", this.schoolList)
            setTimeout(() => {
                this.schoolList.forEach(item => {
                    if (this.schoolSelectIds.includes(item.id)) {
                        item.checked = true
                    } else {
                        item.checked = false
                    }
                })
            }, 1);

        },
        handleSelectChange(item) {
            console.log("handleSelectChange -> item", item)
            if (item.disabled) {return}
            let index = this.schoolSelectIds.indexOf(item.id);
            console.log("handleSelectChange -> index", index)
            if (index === -1) {
                this.schoolSelectIds.push(item.id);
            } else {
                this.schoolSelectIds.splice(index, 1);
            }
            this.changeCheckStatus();
        },
        handleDialogShow() { // 显示 弹框
            this.changeCheckStatus();
            this.dialogVisible = true
        },
        handleDialogClose() { // 关闭 弹框
            this.dialogVisible = false;
            this.getOrgSchoolList();
            this.getAllOrgSchoolList();
            this.handleSearchPageChange(1);
        },
        handleEditConfirm() { // 确定 编辑
            Core.Api.Org.bindSchool(
                this.orgId,
                this.schoolSelectIds.join(',')
            ).then((res) => {
                this.$message.success('保存成功');
                this.handleDialogClose();
            });
        },
        handleSearchPageChange(page) {
            this.searchPage = page
            this.schoolList = [];
            this.getSchoolList();
        },
    }
};
</script>
<style lang="scss" scoped>
.container {
    @import '@/common/styles/table.scss';
    .search-container {
        @include flex(row, space-between, center);
    }
    .form-item {
        width: 100%;
        box-sizing: border-box;
        + .form-item {
            margin-top: 16px;
        }
        .key {
            font-size: 16px;
            font-weight: 500;
            display: flex;
            align-items: center;
            span.title {
                margin-right: 12px;
            }
            .checkAll {
                display: inline-flex;
                align-items: flex-end;
            }
        }
        .value {
            .check-group {
                margin-top: 10px;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                .check-item {
                    margin: 3px 0 0 0;
                    width: calc(50% - 6px);
                    display: inline-flex;
                    align-items: flex-end;
                }
            }
        }
    }
}
.org-dialog {
    .dialog-content {
        height: 45vh;
        .search-area {
            height: 32px;
        }
        .check-area {
            height: calc(100% - 32px - 30px - 12px - 12px);
            margin-top: 12px;
            box-sizing: border-box;
            overflow-y: auto;
            .check-item {
                @include flex(row, flex-start, center);
                padding: 12px;
                cursor: pointer;
                + .check-item {
                    border-top: 1px solid #dce3e8;
                }
                &:last-child {
                    border-bottom: 1px solid #dce3e8;
                }
                transition: color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46), background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
                .check-box {
                    display: inline-block;
                    border: 1px solid #DCDFE6;
                    border-radius: 2px;
                    box-sizing: border-box;
                    width: 14px;
                    height: 14px;
                    color: #FFFFFF;
                    background-color: #FFFFFF;
                    z-index: 1;
                    transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46), background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
                    position: relative;
                    overflow: hidden;
                    .icon {
                        position: absolute;
                        font-size: 20px;
                        @include ct;
                        transition: color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
                    }
                }
                .check-name {
                    margin-left: 12px;
                    color: #606266;
                    font-weight: 500;
                    transition: color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
                }
            }
            .check-item:hover {
                background-color: rgba(237, 242, 252, .3);
                .check-box {
                    border-color: #1D517E;
                }
            }
            .check-item.disabled {
                cursor: not-allowed;
                .check-box {
                    color: #edf2fc;
                    background-color: #edf2fc;
                    border-color: #DCDFE6;
                }
                .check-name {
                    color: #C0C4CC;
                }
            }
            .check-item.checked {
                cursor: pointer;
                .check-box {
                    color: #1D517E;
                    border-color: #1D517E;
                }
                .check-name {
                    color: #1D517E;
                }
            }
        }
        .page-area {
            display: flex;
            justify-content: flex-end;
            margin-top: 12px;
            height: 30px;
        }
    }
}
</style>

<style lang="scss">
.org-dialog {
    .el-dialog__body {
        padding: 20px;
        max-height: 400px;
        overflow-y: auto;
    }
}
</style>
